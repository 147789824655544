import React from "react";
import CustomNavbar from "../../components/NIISCustomNavbar/CustomNavbar";
import CustomFooter from "../../components/NIISCustomFooter/CustomFooter";
import LogoBanner from "../NIISLogoBanner/LogoBanner";
import { footerData } from "../../static-data/footer.data";
import { navbarData, newLinks } from "../../static-data/navbar.data";

const withDefaultNavbarAndFooter = (WrapperComponent) => () => {
  return (
    <div>
      <LogoBanner name="NIIS GROUP" />
      <CustomNavbar
        linkList={navbarData}
        newLinks={newLinks}
        collegeName="NIIS GROUP"
      />
      <WrapperComponent />
      <CustomFooter linkList={footerData} />
    </div>
  );
};

export default withDefaultNavbarAndFooter;
