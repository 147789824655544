import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import "./NiisHero.styles.scss";

const NiisHero = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const heroContainerClasses = classNames(
    "hero-content hero-content--bottom-left",
    {
      large: !(currentBreakPoint === "xSmall" || currentBreakPoint === "small"),
    }
  );

  const headingClasses = classNames(
    "text-white font-weight-bold mb-0 ",

    {
      "display-6": !(
        currentBreakPoint === "xSmall" || currentBreakPoint === "small"
      ),
    },
    {
      "display-7":
        currentBreakPoint === "xSmall" || currentBreakPoint === "small",
    }
  );

  const captionClasses = classNames("text-white font-weight-bold mb-4", {
    "font-md": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  return (
    <div className="hero niis-hero">
      <video
        autoPlay
        playsInline
        muted
        loop
        className="hero-video niis-hero-video"
      >
        <source
          src={require("../../assets/hero-video.mp4").default}
          type="video/mp4"
        />
      </video>
      <div className={heroContainerClasses}>
        {/* <h1 className={headingClasses} style={{backgroundColor: "#1d3557"}}> */}
        <h1
          className={headingClasses}
          style={{ textShadow: "4px 4px  #1d3557" }}
        >
          {currentBreakPoint === "xSmall" || currentBreakPoint === "small" ? (
            <>NIIS Group Of Institutions</>
          ) : (
            "NIIS Group Of Institutions"
          )}
        </h1>
        <p
          className={captionClasses}
          style={{ textShadow: "4px 4px  #1d3557" }}
        >
          Grooming To Excel
        </p>
      </div>
    </div>
  );
};

export default NiisHero;
