const informationScienceUrl = "/niis-information-science";
const juniorCollegeUrl = "/niis-junior-college";

export const informationScienceLinks = [
  {
    title: "Home",
    mobileStart: true,
    link: "/niis-information-science",
  },
  {
    title: "About Us",
    link: `${informationScienceUrl}/about`,
    subLinks: [
      { title: "About NIIS", link: "/about-niis" },
      { title: "Alumni", link: "/alumni" },
    ],
  },
  {
    title: "Admission",
    link: `${informationScienceUrl}/admission`,
    subLinks: [
      // { title: "UG Programs", link: "/ug-programs" },
      // { title: "PG Programs", link: "/pg-programs" },
      { title: "UG Programs", link: "/ug-programs" },
      { title: "PG Programs", link: "/pg-programs" },
    ],
  },
  {
    title: "Academics",
    link: `${informationScienceUrl}/academics`,
    subLinks: [
      { title: "Undergraduate", link: "/undergraduate" },
      { title: "Postgraduate", link: "/postgraduate" },
    ],
  },

  {
    title: "Industry interface center ",
    link: `${informationScienceUrl}/industy-interface-cell`,
    subLinks: [
      { title: "About Us", link: "/about-us" },
      { title: "Projects and Internships", link: "/projects-and-internships" },
    ],
  },
  {
    title: "NIRF",
    link: `${informationScienceUrl}/nirf`,
  },
  {
    title: "Contact Us",
    link: `${informationScienceUrl}/contact-us`,
  },
  {
    title: "Infrastructure",
    leftMargin: true,
    link: `${informationScienceUrl}/infrastructure`,
  },
  {
    title: "Campus Activities",
    link: `${informationScienceUrl}/activities`,
    subLinks: [
      { title: "Seminars", link: "/seminars" },
      { title: "Industrial Visit", link: "/industrial-visit" },
      {
        title: "Faculty Development Programme",
        link: "/faculty-development-programme",
      },
      { title: "Community Service", link: "/community-service" },
    ],
  },
  {
    title: "Student Corner",
    link: `${informationScienceUrl}/student-corner`,
    subLinks: [
      { title: "Anti-Ragging policy", link: "/anti-ragging-policy" },
      { title: "Guidelines", link: "/guidelines" },
      { title: "Students Cell", link: "/students-club" },
    ],
  },
  {
    title: "Enquire Now",
  },
];

export const juniorCollegeLinks = [
  {
    title: "Home",
    mobileStart: true,
    link: "/niis-junior-college",
  },

  {
    title: "About",
    link: `${juniorCollegeUrl}/about`,
    subLinks: [
      { title: "Principal Message", link: "/principal-message" },
      { title: "Academic Advisor", link: "/academic-advisor" },
    ],
  },
  {
    title: "Academics",
    link: `${juniorCollegeUrl}/academics`,
    subLinks: [
      { title: "Course Structure", link: "/course-structure" },
      { title: "+2 Science", link: "/science" },
      { title: "+2 Commerce", link: "/commerce" },
    ],
  },
  {
    title: "Examination",
    link: `${juniorCollegeUrl}/examination`,
  },
  {
    title: "Admissions",
    link: `${juniorCollegeUrl}/admissions`,
    subLinks: [
      { title: "Admission Procedure", link: "/admission-procedure" },
      { title: "Rules and Regulations", link: "/rules-and-regulations" },
      {
        title: "Application Form",
        link: "/application-form",
      },
    ],
  },
  {
    title: "Activities",
    link: `${juniorCollegeUrl}/activities`,
  },
  {
    title: "Infrastructure",
    link: `${juniorCollegeUrl}/infrastructure`,
  },
  {
    title: "Contact Us",
    link: `${juniorCollegeUrl}/contact-us`,
  },
];

export const newLinks = [
  "/",
  "/about-us",
  "/about/academic-advisor",
  "/academics/courses",
  "/academics/courses/mba",
  "/industry-interface-centre",
];
