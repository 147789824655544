import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import LogoBanner from "../../components/NIISLogoBanner/LogoBanner";
import CustomNavbar from '../../components/NIISCustomNavbar/CustomNavbar';
import CustomFooter from '../../components/NIISCustomFooter/CustomFooter';
import { informationScienceLinks, newLinks } from "../../static-data/niisnavbar.data";
import { informationScienceFooterLinks } from "../../static-data/niisfooter.data";

import ContactUsPage from "../../pages/ContactUsPage/ContactUsPage";
import AboutUsPage from "../../pages/AboutUsPage/AboutUsPage";
import AcademicAdvisorPage from "../../pages/AcademicAdvisorPage/AcademicAdvisorPage";
import FourOFour from "../../pages/FourOFour/FourOFourPage";
import InformationScienceHomePage from "../../pages/NIIS_Information_Science/InformationScienceHomePage/InformationScienceHomePage";
import IndustrialVisitAndStudyTourPage from "../../pages/NIIS_Information_Science/IndustrialVisitAndStudyTourPage/IndustrialVisitAndStudyTourPage";
import FacultyDevelopmentProgramme from "../../pages/NIIS_Information_Science/FacultyDevelopmentProgramme/FacultyDevelopmentProgramme";
import CommunityServicePage from "../../pages/NIIS_Information_Science/CommunityServicePage/CommunityServicePage";
import StudentGuidelinesPage from "../../pages/NIIS_Information_Science/StudentGuidelinesPage/StudentGuidelinesPage";
import UndergraduatePage from "../../pages/NIIS_Information_Science/UndergraduatePage/UndergraduatePage";
import BachelorOfArtsPage from "../../pages/NIIS_Information_Science/BachelorOfArtsPage/BachelorOfArtsPage";
import BachelorofBusinessAdministrationPage from "../../pages/NIIS_Information_Science/BachelorofBusinessAdministrationPage/BachelorofBusinessAdministrationPage";
import BachelorofComputerApplicationPage from "../../pages/NIIS_Information_Science/BachelorofComputerApplicationPage/BachelorofComputerApplicationPage";
import BachelorOfCommercePage from "../../pages/NIIS_Information_Science/BachelorOfCommercePage/BachelorOfCommercePage";
import BachelorOfSciencePage from "../../pages/NIIS_Information_Science/BachelorOfSciencePage/BachelorOfSciencePage";
import BachelorOfScienceITMPage from "../../pages/NIIS_Information_Science/BachelorOfScienceITMPage/BachelorOfScienceITMPage";
import BachelorInBioTechnologyPage from "../../pages/NIIS_Information_Science/BachelorInBioTechnologyPage/BachelorInBioTechnologyPage";
import BachelorOfScienceHonoursPage from "../../pages/NIIS_Information_Science/BachelorOfScienceHonoursPage/BachelorOfScienceHonoursPage";
import PostgraduatePage from "../../pages/NIIS_Information_Science/PostgraduatePage/PostgraduatePage";
import MasterOfCommercePage from "../../pages/NIIS_Information_Science/MasterOfCommercePage/MasterOfCommercePage";
import MasterOfFinanceAndControlPage from "../../pages/NIIS_Information_Science/MasterOfFinanceAndControlPage/MasterOfFinanceAndControlPage";
import MasterinPersonalManagementAndIndustrialRelationsPage from "../../pages/NIIS_Information_Science/MasterinPersonalManagementAndIndustrialRelationsPage/MasterinPersonalManagementAndIndustrialRelationsPage";
import MasterInComputerSciencePage from "../../pages/NIIS_Information_Science/MasterInComputerSciencePage/MasterInComputerSciencePage";
import MasterinSocialWorkPage from "../../pages/NIIS_Information_Science/MasterinSocialWorkPage/MasterinSocialWorkPage";
import AntiRaggingPage from "../../pages/NIIS_Information_Science/AntiRaggingPage/AntiRaggingPage";
import IndustryInterfaceAboutUsPage from "../../pages/NIIS_Information_Science/IndustryInterfaceAboutUsPage/IndustryInterfaceAboutUsPage";
import ProjectsAndInternshipsPage from "../../pages/NIIS_Information_Science/ProjectsAndInternshipsPage/ProjectsAndInternshipsPage";
import MasterInPhysicsPage from "../../pages/NIIS_Information_Science/MasterInPhysicsPage/MasterInPhysicsPage";
import MasterInChemistryPage from "../../pages/NIIS_Information_Science/MasterInChemistryPage/MasterInChemistryPage";
import MasterInBioTechPage from "../../pages/NIIS_Information_Science/MasterInBioTechPage/MasterInBioTechPage";
import AdmissionProcedurePage from "../../pages/NIIS_Information_Science/AdmissionProcedurePage/AdmissionProcedurePage";
import FeeStructurePage from "../../pages/NIIS_Information_Science/FeeStructurePage/FeeStructurePage";
import AdmissionEnquiryPage from "../../pages/NIIS_Information_Science/AdmissionEnquiryPage/AdmissionEnquiryPage";
import SeminarPage from "../../pages/NIIS_Information_Science/SeminarPage/SeminarPage";
import StudentClubPage from "../../pages/NIIS_Information_Science/StudentClubPage/StudentClubPage";
import InfrastructurePage from "../../pages/NIIS_Information_Science/InfrastructurePage/InfrastructurePage";
import UGAdmissionPage from "../NIIS_Information_Science/UGAdmissionPage/UGAdmissionPage";
import PGAdmissionPage from "../NIIS_Information_Science/PGAdmissionPage/PGAdmissionPage";
import AluminiPage from "../NIIS_Information_Science/AlumniPage/AlumniPage";
import NirfPage from "../NIIS_Information_Science/NirfPage/NirfPage";

const InformationScienceRouterPage = () => {

	const { path } = useRouteMatch();
	// const HomePageWithNav = informationScienceHomepageWithNavbarAndFooter(InformationScienceHomePage);

	return (
    <div>
      <LogoBanner name="NIIS INSTITUTE OF INFORMATION SCIENCE & MANAGEMENT" />
      <CustomNavbar
        linkList={informationScienceLinks}
        newLinks={newLinks}
        collegeName="NIIS INSTITUTE OF INFORMATION SCIENCE & MANAGEMENT"
      />

      <Switch>
        <Route exact path={path} component={InformationScienceHomePage} />
        <Route
          exact
          path={`${path}/about/about-niis`}
          component={AboutUsPage}
        />
        <Route exact path={`${path}/about/alumni`} component={AluminiPage} />
        <Route exact path={`${path}/nirf`} component={NirfPage} />
        {/* <Route
					exact
					path={`${path}/about/academic-advisor`}
					component={AcademicAdvisorPage}
				/> */}
        <Route
          exact
          path={`${path}/academics/undergraduate`}
          component={UndergraduatePage}
        />

        <Route
          exact
          path={`${path}/academics/undergraduate/bachelor-of-arts`}
          component={BachelorOfArtsPage}
        />
        <Route
          exact
          path={`${path}/academics/undergraduate/bachelor-of-business-administration`}
          component={BachelorofBusinessAdministrationPage}
        />
        <Route
          exact
          path={`${path}/academics/undergraduate/bachelor-of-computer-application`}
          component={BachelorofComputerApplicationPage}
        />
        <Route
          exact
          path={`${path}/academics/undergraduate/bachelor-of-commerce`}
          component={BachelorOfCommercePage}
        />
        <Route
          exact
          path={`${path}/academics/undergraduate/bachelor-of-science`}
          component={BachelorOfSciencePage}
        />
        <Route
          exact
          path={`${path}/academics/undergraduate/bachelor-of-science-itm`}
          component={BachelorOfScienceITMPage}
        />
        <Route
          exact
          path={`${path}/academics/undergraduate/bachelor-in-bio-technology`}
          component={BachelorInBioTechnologyPage}
        />
        <Route
          exact
          path={`${path}/academics/undergraduate/bachelor-of-science-honours`}
          component={BachelorOfScienceHonoursPage}
        />
        <Route
          exact
          path={`${path}/academics/postgraduate`}
          component={PostgraduatePage}
        />
        <Route
          exact
          path={`${path}/academics/postgraduate/master-of-commerce`}
          component={MasterOfCommercePage}
        />
        <Route
          exact
          path={`${path}/academics/postgraduate/master-of-finance-and-control`}
          component={MasterOfFinanceAndControlPage}
        />
        <Route
          exact
          path={`${path}/academics/postgraduate/master-in-personal-management-and-industrial-relations`}
          component={MasterinPersonalManagementAndIndustrialRelationsPage}
        />
        <Route
          exact
          path={`${path}/academics/postgraduate/master-in-computer-science`}
          component={MasterInComputerSciencePage}
        />
        <Route
          exact
          path={`${path}/academics/postgraduate/master-in-physics`}
          component={MasterInPhysicsPage}
        />
        <Route
          exact
          path={`${path}/academics/postgraduate/master-in-chemistry`}
          component={MasterInChemistryPage}
        />
        <Route
          exact
          path={`${path}/academics/postgraduate/master-in-biotech`}
          component={MasterInBioTechPage}
        />
        <Route
          exact
          path={`${path}/academics/postgraduate/master-in-social-work`}
          component={MasterinSocialWorkPage}
        />

        {/* <Route
					exact
					path={`${path}/admission/ug-programs`}
					component={UGAdmissionPage}
				/>
				<Route
					exact
					path={`${path}/admission/pg-programs`}
					component={PGAdmissionPage}
				/> */}
        <Route
          exact
          path={`${path}/admission/ug-programs`}
          component={UGAdmissionPage}
        />
        <Route
          exact
          path={`${path}/admission/pg-programs`}
          component={PGAdmissionPage}
        />

        <Route
          exact
          path={`${path}/industy-interface-cell/about-us`}
          component={IndustryInterfaceAboutUsPage}
        />
        <Route
          exact
          path={`${path}/industy-interface-cell/projects-and-internships`}
          component={ProjectsAndInternshipsPage}
        />
        <Route
          exact
          path={`${path}/activities/seminars`}
          component={SeminarPage}
        />
        <Route
          exact
          path={`${path}/activities/industrial-visit`}
          component={IndustrialVisitAndStudyTourPage}
        />
        <Route
          exact
          path={`${path}/activities/faculty-development-programme`}
          component={FacultyDevelopmentProgramme}
        />
        <Route
          exact
          path={`${path}/activities/community-service`}
          component={CommunityServicePage}
        />
        <Route
          exact
          path={`${path}/student-corner/anti-ragging-policy`}
          component={AntiRaggingPage}
        />
        <Route
          exact
          path={`${path}/student-corner/guidelines`}
          component={StudentGuidelinesPage}
        />
        <Route
          exact
          path={`${path}/student-corner/students-club`}
          component={StudentClubPage}
        />
        <Route
          exact
          path={`${path}/infrastructure`}
          component={InfrastructurePage}
        />
        <Route exact path={`${path}/contact-us`} component={ContactUsPage} />

        <Route path="*" component={FourOFour} />
      </Switch>

      <CustomFooter linkList={informationScienceFooterLinks} />
    </div>
  );
};

export default InformationScienceRouterPage;
