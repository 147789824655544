import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import CustomIcon from "../CustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const NirfResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const descriptionClasses = classNames("mb-4 text-center text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader centered={true} title="NIRF" />
      <a
        href={process.env.PUBLIC_URL + "/C-39709-TeachingStaff-DCF-2-NIIS.pdf"}
        className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
        target="_blank"
        rel="noopener noreferrer"
      >
        <CustomIcon currentIcon={AiFillFilePdf} size="md" />
        <span className="ml-2">DOWNLOAD TeachingStaff Document</span>
      </a>

      <br />

      <a
        href={process.env.PUBLIC_URL + "/C-39709-DCF-3-NIIS.pdf"}
        className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
        target="_blank"
        rel="noopener noreferrer"
      >
        <CustomIcon currentIcon={AiFillFilePdf} size="md" />
        <span className="ml-2">DOWNLOAD DCF NIIS</span>
      </a>
    </PageSection>
  );
};

export default NirfResponsive;
