import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const CustomNavLinksBuilder = ({ keyName, linkList, setNavbarExpanded }) => {
  const nestedLinkBuilder = (keyName, mainLink, subLinks) => {
    return subLinks.map(({ title, link, external, exact }) => (
      <NavDropdown.Item
        // className="bg-heading-color"
        key={`${keyName}+${link}`}
        {...(external
          ? {
              as: "a",
              href: exact ? link : `${mainLink ?? mainLink}${link}`,
              target: "_blank",
              rel: "noreferrer",
            }
          : { as: Link, to: exact ? link : `${mainLink ?? mainLink}${link}` })}
        onClick={() => setNavbarExpanded(false)}
      >
        {title}
      </NavDropdown.Item>
    ));
  };

  return (
    <>
      {linkList &&
        linkList.map(
          ({ title, link, leftMargin, subLinks, external, internal }) =>
            subLinks ? (
              <NavDropdown
                className={leftMargin ? "nav-left-margin-auto" : ""}
                title={title}
                key={Math.random() + link}
              >
                {nestedLinkBuilder(keyName, link, subLinks, external)}
              </NavDropdown>
            ) : (
              <Nav.Link
                {...(external
                  ? { as: "a", href: link, target: "_blank", rel: "noreferrer" }
                  : internal
                  ? { as: "a", href: link }
                  : { as: Link, to: link })}
                className={leftMargin ? "nav-left-margin-auto" : ""}
                key={`${Math.random()}-${keyName}+${link}`}
                onClick={() => setNavbarExpanded(false)}
              >
                {title}
              </Nav.Link>
            )
        )}
    </>
  );
};

export default CustomNavLinksBuilder;
