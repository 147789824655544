import HeroCaption from "../../../components/NIISHeroCaption/HeroCaption";
import HeroSection from "../../../components/NIISHeroSection/HeroSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import NirfResponsive from "../../../components/NirfResponsive/NirfResponsive"

const NirfPage = () => {
  return (
    <PageWrapper>
      <HeroSection className="bg-page-background-4">
        <HeroCaption
          title="NIRF"
          position="bottom-left"
          breadcrumb={true}
          breadcrumbPrefix="Home"
        />
      </HeroSection>
      <NirfResponsive />
    </PageWrapper>
  );
};

export default NirfPage;
